.wrapper:hover > p {
  text-decoration: underline;
}

.linkButton {
  align-self: flex-end;
  width: fit-content;
  height: auto;
  padding-top: var(--v-space-8);
}
